import { useContext } from "react";
import { KeyCloakContext } from "../KeyCloakProvider/KeyCloakProvider";
import GlobalLoader from "../GlobalLoader/GlobalLoader";

const KeyCloakWrapper = ({ children }) => {
  console.log("render")
  const { keyCloak, loading } = useContext(KeyCloakContext);
  console.log("loading wrapper", loading);
  console.log("keyCloak wrapper", keyCloak)
  // const navigate = useNavigate();

  // console.log("keyCloak", keyCloak)

  if (loading === true) return <GlobalLoader />
  return children
}

export default KeyCloakWrapper