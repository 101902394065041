import { useEffect, useState } from "react";
import Keycloak from 'keycloak-js';

const initOptions = {
  url: 'https://keycloak.dev-avaxialabs.com/auth',
  realm: 'master',
  clientId: 'smart-asistant',
  onLoad: 'check-sso', // check-sso | login-required
  KeycloakResponseType: 'code'
}

const useKeyCloakProvider = () => {

  const [keyCloak, setKeyCloak] = useState();
  const [loading, setLoading] = useState(true);

  const initKeyCloak = async () => {
    try {
      const kc = new Keycloak(initOptions);
      await kc.init({
        onLoad: initOptions.onLoad,
        checkLoginIframe: false,
        pkceMethod: 'S256'
      });
      setKeyCloak(kc);
    } catch (error) {
      setKeyCloak();
      console.error("Keycloak initialization error:", error);
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    initKeyCloak();
  }, [])


  return {
    keyCloak,
    loading
  }
}
export default useKeyCloakProvider;