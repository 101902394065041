import { createContext } from "react";
import useKeyCloakProvider from "./logic/useKeyCloakProvider";

export const KeyCloakContext = createContext();

const KeyCloakProvider = ({ children }) => {

  const { keyCloak, loading } = useKeyCloakProvider();

  const providerValue = {
    keyCloak,
    loading
  }
  return (
    <KeyCloakContext.Provider value={providerValue}>
      {children}
    </KeyCloakContext.Provider>
  )

}
export default KeyCloakProvider;