import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import Login from './Login';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import KeyCloakProvider from './components/KeyCloakProvider/KeyCloakProvider';
import KeyCloakWrapper from './components/KeyCloakWrapper/KeyCloakWrapper';
import ProtectedRoute from './components/ProtectedRoute/ProtectedRoute';

const GlobalApp = () => {
  return (
    <Router >
      <KeyCloakWrapper>
        <Routes>
          <Route path="/login" element={<ProtectedRoute><Login /></ProtectedRoute>} />
          <Route path="/" element={
            <ProtectedRoute>
              <App />
            </ProtectedRoute>
          } />
        </Routes>
      </KeyCloakWrapper>
    </Router>
  )
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
  <KeyCloakProvider>
    <GlobalApp />
  </KeyCloakProvider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
