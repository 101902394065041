import React, { useContext } from 'react';
import { KeyCloakContext } from './components/KeyCloakProvider/KeyCloakProvider';

const Login = () => {
	const { keyCloak } = useContext(KeyCloakContext);

	return <section className="bg-gray-50">
		<div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
			<div className="w-full bg-white rounded-lg shadow md:mt-0 sm:max-w-md xl:p-0 ">
				<div className="p-6 space-y-4 md:space-y-6 sm:p-8">
					<h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl ">
						Sign in to your account
					</h1>
					<button onClick={() => keyCloak.login()} className="w-full text-white bg-blue-600 hover:bg-blue-700 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center">Via KeyClock</button>

				</div>
			</div>
		</div>
	</section>
}

export default Login;