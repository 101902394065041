import { useContext } from "react";
import { KeyCloakContext } from "../KeyCloakProvider/KeyCloakProvider";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const ProtectedRoute = ({ children }) => {
  const { keyCloak } = useContext(KeyCloakContext);
  const navigate = useNavigate();


  useEffect(() => {
    if (keyCloak.authenticated === true) {
      navigate("/");
    } else {
      navigate("/login");
    }
  }, [])

  return children
};

export default ProtectedRoute;